import { ReactElement, useState } from 'react';
import { Embed } from '@nilfoundation/react-components';
import './GrafanaEmbed.scss';

/**
 * Props.
 */
type EmbedProps = {
    panelId: string;
    title: string;
    height?: number;
    yAxis?: string;
    xAxis?: string;
}

export const GrafanaEmbed = ({
    panelId,
    title,
    height,
    yAxis,
    xAxis,
}: EmbedProps): ReactElement => {
    const [chartLoaded, setChartLoaded] = useState(false);
    const srcUrl = `${process.env.REACT_APP_API_URL}&panelId=${panelId}`;

    return (
        <div className="embedContainer" style={{height: `${height}px`}}>
            <div className="chartContainer">
                {chartLoaded && yAxis && (
                    <div className="axis yAxis">
                        {yAxis}
                    </div>
                )}
                <Embed
                    className="grafanaEmbed"
                    title={title}
                    source={srcUrl}
                    onLoad={() => setChartLoaded(true)}
                />
            </div>
            {chartLoaded && xAxis && (
                <div className="axis">
                    {xAxis}
                </div>
            )}
        </div>
    );
};
